import { useEffect, useState } from 'react';

// https://stackoverflow.com/a/57527009 Disable Auto Zoom in Input "Text" tag - Safari on iPhone
export const useViewport = () => {
    const [viewport, setViewport] = useState(
        <meta name="viewport" content="width=device-width, initial-scale=1" />
    );

    useEffect(() => {
        const isOnIOS =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && 'MSStream' in window === false;
        if (isOnIOS) {
            setViewport(
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                />
            );
        }
    }, []);

    return viewport;
};
