import { FunctionComponent, PropsWithChildren } from 'react';
import { MarketplaceShellHeader } from './Header';
import { MarketplaceShellFooter } from './Footer';
import { MarketplaceShellContent } from './Content';

export const MarketplaceShell: FunctionComponent<PropsWithChildren> = ({ children }) => {
    return (
        <>
            <MarketplaceShellHeader />
            <MarketplaceShellContent>{children}</MarketplaceShellContent>
            <MarketplaceShellFooter />
        </>
    );
};
