import { FunctionComponent, PropsWithChildren } from 'react';
import BaseLayout, { BaseLayoutProps } from '../BaseLayout';
import { MarketplaceShell } from '../../app/components/MarketplaceShell';

export type LayoutProps = PropsWithChildren<BaseLayoutProps>;

const Layout: FunctionComponent<LayoutProps> = ({ children, ...baseLayoutProps }) => {
    return (
        <BaseLayout {...baseLayoutProps}>
            <MarketplaceShell>{children}</MarketplaceShell>
        </BaseLayout>
    );
};

export default Layout;
