import { FunctionComponent, SVGProps } from 'react';

const iconPaths = {
    ArrowDown: [
        'M15,9c-0.3,0-0.5,0.1-0.7,0.3L11,12.6V3c0-0.5-0.4-1-1-1S9,2.5,9,3v9.6L5.7,9.3 C5.5,9.1,5.3,9,5,9c-0.5,0-1,0.5-1,1c0,0.3,0.1,0.5,0.3,0.7l5,5C9.5,15.9,9.7,16,10,16s0.5-0.1,0.7-0.3l5-5 c0.2-0.2,0.3-0.4,0.3-0.7C16,9.4,15.6,9,15,9L15,9z',
    ],
    ArrowLeft: [
        'M13.99 6.99H4.41L7.7 3.7a1.003 1.003 0 00-1.42-1.42l-5 5a1.014 1.014 0 000 1.42l5 5a1.003 1.003 0 001.42-1.42L4.41 8.99H14c.55 0 1-.45 1-1s-.46-1-1.01-1z',
    ],
    ArrowRight: [
        'M14.7 7.29l-5-5a.965.965 0 00-.71-.3 1.003 1.003 0 00-.71 1.71l3.29 3.29H1.99c-.55 0-1 .45-1 1s.45 1 1 1h9.59l-3.29 3.29a1.003 1.003 0 001.42 1.42l5-5c.18-.18.29-.43.29-.71s-.12-.52-.3-.7z',
    ],
    ArrowTopRight: [
        'M13,2H6C5.45,2,5,2.45,5,3c0,0.55,0.45,1,1,1h4.59l-8.29,8.29 C2.11,12.47,2,12.72,2,13c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L12,5.41V10c0,0.55,0.45,1,1,1s1-0.45,1-1V3 C14,2.45,13.55,2,13,2z',
    ],
    ArrowUp: [
        'M15.7,7.3l-5-5C10.5,2.1,10.3,2,10,2S9.5,2.1,9.3,2.3l-5,5C4.1,7.5,4,7.7,4,8 c0,0.6,0.5,1,1,1c0.3,0,0.5-0.1,0.7-0.3L9,5.4V15c0,0.6,0.4,1,1,1s1-0.4,1-1V5.4l3.3,3.3C14.5,8.9,14.7,9,15,9c0.6,0,1-0.4,1-1 C16,7.7,15.9,7.5,15.7,7.3L15.7,7.3z',
    ],
    AutomaticUpdates: [
        'M8 14c-3.31 0-6-2.69-6-6 0-1.77.78-3.36 2-4.46V5c0 .55.45 1 1 1s1-.45 1-1V1c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1s.45 1 1 1h1.74A7.95 7.95 0 000 8c0 4.42 3.58 8 8 8 .55 0 1-.45 1-1s-.45-1-1-1zM8 2a5.9 5.9 0 012.95.81l1.47-1.47A7.893 7.893 0 008 0c-.55 0-1 .45-1 1s.45 1 1 1zm2.71 6.71l5-5a1.003 1.003 0 00-1.42-1.42L10 6.59l-1.29-1.3a1.003 1.003 0 00-1.42 1.42l2 2c.18.18.43.29.71.29s.53-.11.71-.29zM16 8c0-.55-.06-1.08-.16-1.6l-1.87 1.87A5.966 5.966 0 0112 12.45V11c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1h-1.74A7.95 7.95 0 0016 8z',
    ],
    Building: [
        'M15,5h-3V1c0-0.55-0.45-1-1-1H1C0.45,0,0,0.45,0,1v14c0,0.55,0.45,1,1,1h3v-4h4 v4h7c0.55,0,1-0.45,1-1V6C16,5.45,15.55,5,15,5z M5,10H2V7h3V10z M5,5H2V2h3V5z M10,10H7V7h3V10z M10,5H7V2h3V5z M14,14h-2v-2h2 V14z M14,10h-2V7h2V10z',
    ],
    Close: [
        'M9.41,8l3.29-3.29C12.89,4.53,13,4.28,13,4c0-0.55-0.45-1-1-1 c-0.28,0-0.53,0.11-0.71,0.29L8,6.59L4.71,3.29C4.53,3.11,4.28,3,4,3C3.45,3,3,3.45,3,4c0,0.28,0.11,0.53,0.29,0.71L6.59,8 l-3.29,3.29C3.11,11.47,3,11.72,3,12c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L8,9.41l3.29,3.29C11.47,12.89,11.72,13,12,13 c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L9.41,8z',
    ],
    CheckMarkCircle: [
        'M8,16c-4.42,0-8-3.58-8-8s3.58-8,8-8s8,3.58,8,8S12.42,16,8,16z M12,5c-0.28,0-0.53,0.11-0.71,0.29 L7,9.59l-2.29-2.3C4.53,7.11,4.28,7,4,7C3.45,7,3,7.45,3,8c0,0.28,0.11,0.53,0.29,0.71l3,3C6.47,11.89,6.72,12,7,12s0.53-0.11,0.71-0.29l5-5C12.89,6.53,13,6.28,13,6C13,5.45,12.55,5,12,5z',
    ],
    ChevronDown: [
        'M12 5c-.28 0-.53.11-.71.29L8 8.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l4-4A1.003 1.003 0 0012 5z',
    ],
    ChevronRight: [
        'M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z',
    ],
    ChevronUp: [
        'M12.71,9.29l-4-4C8.53,5.11,8.28,5,8,5S7.47,5.11,7.29,5.29l-4,4 C3.11,9.47,3,9.72,3,10c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L8,7.41l3.29,3.29C11.47,10.89,11.72,11,12,11 c0.55,0,1-0.45,1-1C13,9.72,12.89,9.47,12.71,9.29z',
    ],
    Dashboard: [
        'M5,4C4.45,4,4,4.45,4,5c0,0.55,0.45,1,1,1s1-0.45,1-1C6,4.45,5.55,4,5,4z M4,7 C3.45,7,3,7.45,3,8c0,0.55,0.45,1,1,1s1-0.45,1-1C5,7.45,4.55,7,4,7z M8,5c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1S7,3.45,7,4 C7,4.55,7.45,5,8,5z M6,11c0,1.1,0.9,2,2,2s2-0.9,2-2c0-0.53-2-5-2-5S6,10.47,6,11z M8,0C3.58,0,0,3.58,0,8s3.58,8,8,8s8-3.58,8-8 S12.42,0,8,0z M8,14c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S11.31,14,8,14z M12,5c0-0.55-0.45-1-1-1s-1,0.45-1,1 c0,0.55,0.45,1,1,1S12,5.55,12,5z M12,7c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1s1-0.45,1-1C13,7.45,12.55,7,12,7z',
    ],
    Edit: [
        'M3.25,10.26l2.47,2.47l6.69-6.69L9.95,3.56L3.25,10.26z M0.99,14.99l3.86-1.39 l-2.46-2.44L0.99,14.99z M13.24,0.99c-0.48,0-0.92,0.2-1.24,0.51l-1.44,1.44l2.47,2.47l1.44-1.44c0.32-0.32,0.51-0.75,0.51-1.24 C14.99,1.78,14.21,0.99,13.24,0.99z',
    ],
    Endorsed: [
        'M15.86,7.5l-0.81-1.42V4.5c0-0.36-0.19-0.68-0.49-0.87l-1.37-0.8l-0.81-1.41c-0.19-0.31-0.51-0.49-0.86-0.49 H9.89L8.5,0.14c-0.3-0.19-0.69-0.19-1,0l-1.39,0.8H4.52c-0.36,0-0.68,0.19-0.86,0.49L2.86,2.8L1.42,3.63 C1.12,3.82,0.93,4.14,0.93,4.5v1.65l-0.8,1.37C0.05,7.67,0,7.84,0,8.01S0.05,8.35,0.14,8.5l0.8,1.37v1.65 c0,0.36,0.19,0.68,0.49,0.87l1.42,0.81l0.8,1.37c0.19,0.31,0.51,0.49,0.86,0.49H6.1l1.39,0.8C7.64,15.95,7.81,16,7.97,16 s0.34-0.05,0.49-0.14l1.39-0.8h1.63c0.36,0,0.68-0.19,0.86-0.49l0.81-1.41l1.37-0.8c0.3-0.19,0.49-0.51,0.49-0.87V9.93l0.81-1.42 C16.05,8.2,16.05,7.82,15.86,7.5z M11.74,6.68l-4.01,4.01c-0.18,0.18-0.43,0.29-0.71,0.29s-0.53-0.11-0.71-0.29L4.31,8.69 C4.13,8.5,4.01,8.25,4.01,7.98c0-0.55,0.45-1,1-1c0.28,0,0.53,0.11,0.71,0.29l1.3,1.3l3.3-3.3c0.18-0.18,0.43-0.29,0.71-0.29 c0.55,0,1,0.45,1,1C12.04,6.25,11.92,6.5,11.74,6.68z',
    ],
    Envelope: [
        'M0,3.06v9.88L4.94,8L0,3.06z M14.94,2H1.06L8,8.94L14.94,2z M8.53,10.53 C8.39,10.67,8.21,10.75,8,10.75s-0.39-0.08-0.53-0.22L6,9.06L1.06,14h13.88L10,9.06L8.53,10.53z M11.06,8L16,12.94V3.06L11.06,8z',
    ],
    Globe: [
        'M8,0 C3.58,0 0,3.58 0,8 C0,12.42 3.58,16 8,16 C12.42,16 16,12.42 16,8 C16,3.58 12.42,0 8,0 Z M13.17,5 L10.73,5 C10.52,3.89 10.22,2.97 9.82,2.31 C11.25,2.77 12.43,3.74 13.17,5 Z M10,8 C10,8.73 9.95,9.39 9.88,10 L6.12,10 C6.05,9.39 6,8.73 6,8 C6,7.27 6.05,6.61 6.12,6 L9.88,6 C9.95,6.61 10,7.27 10,8 Z M8,2 C8.67,2 9.36,3.1 9.73,5 L6.27,5 C6.64,3.1 7.33,2 8,2 Z M6.18,2.31 C5.78,2.97 5.47,3.89 5.27,5 L2.83,5 C3.57,3.74 4.75,2.77 6.18,2.31 Z M2,8 C2,7.3 2.13,6.63 2.35,6 L5.11,6 C5.04,6.62 5,7.28 5,8 C5,8.72 5.04,9.38 5.11,10 L2.35,10 C2.13,9.37 2,8.7 2,8 Z M2.83,11 L5.27,11 C5.48,12.11 5.78,13.03 6.18,13.69 C4.75,13.23 3.57,12.26 2.83,11 Z M8,14 C7.33,14 6.64,12.9 6.27,11 L9.73,11 C9.36,12.9 8.67,14 8,14 Z M9.82,13.69 C10.22,13.03 10.52,12.11 10.73,11 L13.17,11 C12.43,12.26 11.25,13.23 9.82,13.69 Z M13.65,10 L10.89,10 C10.96,9.38 11,8.72 11,8 C11,7.28 10.96,6.62 10.89,6 L13.65,6 C13.87,6.63 14,7.3 14,8 C14,8.7 13.87,9.37 13.65,10 Z',
    ],
    Learning: [
        'M8.44103536,0.104259523 C8.1731087,-0.0347531745 7.82678221,-0.0347531745 7.559001,0.104259523 L0.365126443,4 C-0.121708814,4.2528344 -0.121708814,4.74728116 0.365126443,5 L7.559001,8.89605713 C7.82678221,9.03506983 8.1731087,9.03506983 8.44103536,8.89605713 L15.6347645,5 C16.1217452,4.74728116 16.1217452,4.2528344 15.6347645,4 L8.44103536,0.104259523 Z',
        'M14,4.50015833 L14.016461,9.01419537 C14.0184568,9.56151702 14.4627102,10.0041533 15.0100355,10.0041533 C15.5567778,10.0041533 16,9.5609311 16,9.01418879 L16,4.50015833 L16,4.50015833 L14,4.50015833 Z',
        'M3.37120329,8.04682776 L7.75801685,10.4785014 C7.90876752,10.5620646 8.09192881,10.5620911 8.24270362,10.4785714 L12.6329513,8.04665254 C12.7537301,7.97974871 12.905877,8.02342305 12.9727808,8.1442019 C12.9933171,8.18127521 13.0040913,8.22296077 13.0040913,8.26534199 L13.0040913,11.2204273 C13.0040913,11.3133052 12.9526022,11.3985277 12.870385,11.4417321 L8.23463293,13.8777775 C8.08901543,13.9542982 7.91507592,13.9542982 7.76945842,13.8777775 L3.13370637,11.4417321 C3.05148913,11.3985277 3,11.3133052 3,11.2204273 L3,8.26548219 C3,8.12741101 3.11192881,8.01548219 3.25,8.01548219 C3.2924057,8.01548219 3.33411451,8.02626892 3.37120329,8.04682776 Z',
    ],
    Phone: [
        'M15.9,12.41c-0.06-0.06-3.37-2-3.48-2.05c-0.11-0.05-0.22-0.08-0.32-0.08 c-0.15,0-0.34,0.11-0.57,0.32c-0.23,0.22-0.94,1.19-1.15,1.4c-0.21,0.22-0.38,0.32-0.52,0.32c-0.07,0-0.15-0.02-0.25-0.06 c-0.1-0.04-1.16-0.58-3.36-2.52c-2.2-1.93-2.49-3.2-2.5-3.55c0-0.14,0.11-0.31,0.32-0.52c0.22-0.21,0.45-0.41,0.7-0.6 c0.25-0.19,0.49-0.4,0.7-0.62c0.22-0.23,0.32-0.42,0.32-0.57c0-0.11-0.03-0.21-0.08-0.32C5.66,3.46,3.66,0.15,3.59,0.08 C3.44-0.07,2.85,0,2.55,0.16C0.16,1.46-0.03,3.2,0,3.89c0.04,0.71,0.49,4.46,4.16,7.95C8.72,16.17,11.89,16,12.1,16 c0.69,0,2.82-0.38,3.72-2.55C15.95,13.13,16.07,12.58,15.9,12.41z',
    ],
    Share: [
        'M10.99,13.99h-9v-9h4.76l2-2H0.99c-0.55,0-1,0.45-1,1v11c0,0.55,0.45,1,1,1h11 c0.55,0,1-0.45,1-1V7.24l-2,2V13.99z M14.99-0.01h-5c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.59L7.29,7.28C7.1,7.47,6.99,7.72,6.99,7.99 c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l5.29-5.29v2.59c0,0.55,0.45,1,1,1s1-0.45,1-1v-5C15.99,0.44,15.54-0.01,14.99-0.01z',
    ],
    WarningSign: [
        'M15.84 13.5l.01-.01-7-12-.01.01c-.17-.3-.48-.5-.85-.5s-.67.2-.85.5l-.01-.01-7 12 .01.01c-.09.15-.15.31-.15.5 0 .55.45 1 1 1h14c.55 0 1-.45 1-1 0-.19-.06-.35-.15-.5zm-6.85-.51h-2v-2h2v2zm0-3h-2v-5h2v5z',
    ],
    Settings: [
        'M3 1c0-.55-.45-1-1-1S1 .45 1 1v3h2V1zm0 4H1c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h2c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm12-4c0-.55-.45-1-1-1s-1 .45-1 1v2h2V1zM9 1c0-.55-.45-1-1-1S7 .45 7 1v6h2V1zM1 15c0 .55.45 1 1 1s1-.45 1-1v-5H1v5zM15 4h-2c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h2c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1zm-2 11c0 .55.45 1 1 1s1-.45 1-1V9h-2v6zM9 8H7c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h2c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-2 7c0 .55.45 1 1 1s1-.45 1-1v-2H7v2z',
    ],
    Search: [
        'M15.55 13.43l-2.67-2.68a6.94 6.94 0 001.11-3.76c0-3.87-3.13-7-7-7s-7 3.13-7 7 3.13 7 7 7c1.39 0 2.68-.42 3.76-1.11l2.68 2.67a1.498 1.498 0 102.12-2.12zm-8.56-1.44c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z',
    ],
} as const;

export type IconName = keyof typeof iconPaths;

export type BlueprintIconProps = {
    icon: IconName;
} & SVGProps<SVGSVGElement>;

export const BlueprintIcon: FunctionComponent<BlueprintIconProps> = ({
    icon,
    height = 16,
    width = 16,
    ...svgProps
}) => {
    return (
        <svg aria-hidden="true" height={height} viewBox="0 0 16 16" width={width} {...svgProps}>
            {iconPaths[icon].map((pathDefinition, index) => {
                return <path key={index} d={pathDefinition} />;
            })}
        </svg>
    );
};

export default BlueprintIcon;
