'use client';

import { FunctionComponent, PropsWithChildren } from 'react';
import { useIam } from '../../services/IAm/hooks';

export const UnauthenticatedOnly: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const { iam } = useIam();

    if (iam.authenticated) {
        return null;
    }

    return children;
};
